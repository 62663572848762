<template>
  <PageMetadata v-if="homepageFound" :pageInformation="homepage" />
  <div v-for="(section, index) in sections" :key="index" v-html="section.body"></div>
</template>

<script>
import axios from "axios";
import { PageMetadata } from "@/components";
export default {
  name: "HomePage",
  components: { PageMetadata },
  mounted() {
    this.fetchHomepage();
  },
  data() {
    return {
      sections: {},
      homepage: {},
      homepageFound: false,
    };
  },
  methods: {
    fetchHomepage() {
      axios
        .get(process.env.VUE_APP_API_BASE + "auth/getHomePage", {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.sections = response.data.sections;
          this.homepage = {
            'title': response.data.title,
            'meta_description': response.data.meta_description,
            'no_index': 0
          };
          this.homepageFound = true;
        });
    },
  },
};
</script>
<style>
.help-wrapper .help-container a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #231f20;
  cursor: pointer;
  text-decoration: none !important;
}
section{
  margin-bottom:70px;
}
section.subscribe-now{margin-bottom:0;}
@media (max-width: 767px) {
  .main-banner{
    height: 315px !important;
  }
}
</style>